import { module } from 'modujs';
import { html } from '../utils/environment';
import { gsap } from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                toggler: 'toggle'
            },
            mouseenter: {
                toggler: 'togglerEnter'
            },
            mouseleave: {
                toggler: 'togglerLeave'
            }
        }
    }

    init() {

    }

    togglerEnter(e) {
        e.currentTarget.classList.remove('-top');
        e.currentTarget.classList.remove('-bottom');

        e.currentTarget.classList.add('is-enter')

        if(e.offsetY < 10) {
            e.currentTarget.classList.add('-top');
        } else {
            e.currentTarget.classList.add('-bottom');
        }
    }

    togglerLeave(e) {
        e.currentTarget.classList.remove('-top');
        e.currentTarget.classList.remove('-bottom');
        e.currentTarget.classList.remove('is-enter')

        if(e.offsetY < 10) {
            e.currentTarget.classList.add('-top');
        } else {
            e.currentTarget.classList.add('-bottom');
        }
    }

    /**
     * Toggler
     *
     * @param item {node}
     */
    toggle(e) {
        e.stopPropagation();

        let el = e.curTarget;
        let parent = this.parent('item', el);

        if(parent.classList.contains('is-open')) {
            this.close(parent);
        } else {
            this.close();
            this.open(parent);
        }
    }

    /**
     * open
     *
     * @param parentNode item {node}
     */
    open(item) {
        let collapsible = this.$('content', item)[0];
        item.classList.add('is-open');

        this.call('start',{},'Slider',item.getAttribute('id'));

        gsap.to(collapsible, {
            duration: 0,
            height: this.compute(this.$('inner', item)[0]).height
        });

        if(!window.isMobile) {
            gsap.delayedCall(0.4,() => {
                this.call('scrollTo',{currentTarget : item},'Scroll','main')
            });
        }
    }

    /**
     * Close
     *
     * @param
     */
    close(parent) {

        this.$('item').forEach((item) => {
            item.classList.remove('is-open');
            this.call('stop',{},'Slider',item.getAttribute('id'));

            let collapsible = this.$('content', item)[0];

            if(collapsible) {
                gsap.to(collapsible, 0, {
                    height: 0
                })
            }
        })
    }

    /**
     * compute
     *
     * @param
     */
    compute(item) {
        return item.getBoundingClientRect();
    }
}