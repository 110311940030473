import modular from 'modujs';
import * as modules from './modules';
import globals from './globals';
import { html } from './utils/environment';
import smoothscroll from 'smoothscroll-polyfill';

const app = new modular({
    modules: modules
});

window.isMobile = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

if (window.isMobile) {
    html.classList.add('is-mobile');
} else {
    html.classList.add('is-desktop');
}

window.isWindows = navigator.platform.indexOf('Win') > -1;

if(window.isWindows) {
    html.classList.add('is-windows');
}

window.isIos = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
if(window.isIos) {
    html.classList.add('is-ios');
}

if(window.isIos) {
    smoothscroll.polyfill();
}

const canvas = document.createElement('canvas');
const gl = canvas.getContext('webgl');

const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);

if(renderer.includes('M1')) {
    // console.log('M1');
} 
if(renderer.includes('M1 Pro')) {
    // console.log('M1 Pro');
}
if(renderer.includes('M1 Max')) {
    // console.log('M1 Max');
    html.classList.add('is-m1-max');
}

window.onload = (event) => {
    init();
};

function init() {
    globals();

    app.init(app);

    html.classList.add('is-loaded');
    html.classList.remove('is-loading');

    html.classList.add('is-first-load');

    setTimeout(() => {
        html.classList.add('is-pre-ready');

        setTimeout(() => {
            html.classList.add('is-pre-ready-end');

            setTimeout(() => {
                html.classList.add('is-ready');
                html.classList.add('has-dom-ready');
    
                Object.keys(app.currentModules).forEach(key => {
                    
                    if(typeof app.currentModules[key].ready == 'function') {
                        app.currentModules[key].ready();
                    }
                });
            }, 400)
        }, 2000);
    },600)

    // Credits
    if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
        var args = ['%c Developed by Quentin Hocdé \u00A9 https://quentinhocde.com \n - Made with Maëlan Le Meur \n','background: #0000; padding:10px 0;color: #ffffff;'];
        window.console.log.apply(console, args);
    } else if (window.console) {
        window.console.log('Developed by Quentin Hocdé - https://quentinhocde.com - made with Maëlan Le Meur');
    }
}

