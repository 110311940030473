import { module } from 'modujs';
import { html } from '../utils/environment';

const CLASS_HAS_NAV = 'has-menu';
const CLASS_HAS_NAV_OPEN = 'has-nav-open';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {

        // this.open();
    }

    trigger({mode, direction}) {

        if(mode == 'enter' && direction == 'down') {
            html.classList.add(CLASS_HAS_NAV);
        }
        if(mode == 'leave' && direction == 'up') {
            html.classList.remove(CLASS_HAS_NAV);
        }
    }

    open() {
        html.classList.add(CLASS_HAS_NAV_OPEN);
    }

    close() {
        html.classList.remove(CLASS_HAS_NAV_OPEN);
    }
}
