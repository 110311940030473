import { module } from 'modujs';
import { gsap } from 'gsap';

const WORD_CLASS = 'word-wrap';
const LINE_CLASS = 'line-wrap';
const CHAR_CLASS = 'char-wrap';
const ANIMATED_CLASS = 'is-animated'

// data-split-type= 'lines, words or chars"
// data-split-animate= 'lines, words or chars"
// data-split-auto - animation launched automatically
// data-split-delay - update delay to the timeline
// data-split-stagger - update the stagger of the timeline
// data-split-from - update the "from" to the timeline
// data-split-to - update the "to" of the timeline

// CONDITION
// You can set a condition with this.condition, and set data-split-condition
// if condition is true, 
// - You can add data-split-from-condition, and data-split-to-condition

export default class extends module {
    constructor(m) {
        super(m);

        this.DEFAULT_FROM_ANIMATION = {
            y: '100%',
            scaleY: 1.5,
            alpha: 0
        }
        
        this.DEFAULT_TO_ANIMATION = {
            alpha: 1,
            y: 0,
            scaleY: 1,
            duration: 0.8,
            stagger: 0.05,
            paused: true,
            ease: "power2.out"
        }

    }

    init() {

        this.condition = window.innerWidth < window.innerHeight;
        
        this.hasCondition = typeof this.getData('condition') == 'string';

        this.splitMode = this.getData('type') ? this.getData('type') : 'chars';
        this.animateMode = this.getData('animate');

        this.isFixed = typeof this.getData('fixed') == 'string';

        if(typeof this.getData('from') == 'string') {
            this.DEFAULT_FROM_ANIMATION = JSON.parse(this.getData('from'));
        }
        if(this.hasCondition && this.condition && typeof this.getData('from-condition') == 'string') {
            this.DEFAULT_FROM_ANIMATION = JSON.parse(this.getData('from-condition'));
        }

        if(typeof this.getData('to') == 'string') {
            this.DEFAULT_TO_ANIMATION = JSON.parse(this.getData('to'));
        }
        if(this.hasCondition && this.condition && typeof this.getData('to-condition') == 'string') {
            let to = JSON.parse(this.getData('to-condition'));
            this.DEFAULT_TO_ANIMATION = {...to,...this.DEFAULT_TO_ANIMATION};
        }

        if(typeof this.getData('stagger') == 'string') {
            this.DEFAULT_TO_ANIMATION.stagger = this.getData('stagger');
        }
        if(typeof this.getData('duration') == 'string') {
            this.DEFAULT_TO_ANIMATION.duration = parseFloat(this.getData('duration'));
        }
        if(typeof this.getData('delay') == 'string') {
            this.DEFAULT_TO_ANIMATION.delay = parseFloat(this.getData('delay'));
        }
        

        this.animated = false;

        this.splitChars();
        if(this.splitMode == 'lines') {
            this.splitLines();
        }
        else if(this.splitMode == 'words') {
            this.splitWords();
        }
        
        if(!this.isFixed) {
            this.animations = new Array();
            this.initFromAnimation(this.animateMode);
        }
    }

    // Called when html.is-ready is added
    ready() {
        if(this.isFixed) return;
        
        if(typeof this.el.getAttribute('data-scroll') != 'string' || typeof this.getData('auto') == 'string') {
            this.animate();
        }
    }

    trigger(e) {

        if(e.mode == 'enter') {
            this.animate();
        }
    }

    splitChars() {
        let chars = this.el.textContent.split('');
        this.el.innerHTML = '';

        for (let i = 0; i < chars.length; i++) {
            let span = document.createElement('span');
            span.innerText = chars[i] == '' ? '\xa0\xa0' : chars[i];
            span.classList.add(CHAR_CLASS);
            this.el.append(span);
        }
    }

    splitLines() {
        let $wordSpans = Array.from(this.el.querySelectorAll('span'));
        let lineArray = [],
            lineIndex = 0,
            lineStart = true;

        $wordSpans.forEach((el, idx) => {
            let top = el.getBoundingClientRect().top;

            if (lineStart) {
                lineArray[lineIndex] = [idx];
                lineStart = false;

            } else {
                let $next = el.nextSibling;

                if ($next != null) {
                    if ($next.getBoundingClientRect().top > top) {
                        lineArray[lineIndex].push(idx);
                        lineIndex++;
                        lineStart = true
                    }
                } else {
                    lineArray[lineIndex].push(idx);
                }
            }
        });

        for (let i = 0; i < lineArray.length; i++) {
            let start = lineArray[i][0];
            let end = lineArray[i][1] + 1;

            let wrap = document.createElement('div');
            wrap.classList.add(LINE_CLASS);
            let inner = document.createElement('span');
            wrap.append(inner);

            /* no end value pushed to array if only one word last line*/
            if (!end) {
                inner.innerText = `${inner.innerText}${$wordSpans[start].innerText}`
            } else {
                let slicedSpans = $wordSpans.slice(start, end);
                slicedSpans.forEach((el,index) => {
                    if(el.innerText == '' && index != slicedSpans.length-1) {
                        inner.innerText = `${inner.innerText} `;
                    } else {
                        inner.innerText = `${inner.innerText}${el.innerText}`;
                    }
                    el.parentNode.removeChild(el);

                });
            }

            this.el.append(wrap);
        }
    }

    splitWords() {
        let $wordSpans = Array.from(this.el.querySelectorAll('span'));
        let wordArray = [],
            wordIndex = 0,
            wordStart = true;

        $wordSpans.forEach((el, idx) => {
            let top = el.getBoundingClientRect().top;

            if (wordStart) {
                wordArray[wordIndex] = [idx];
                wordStart = false;

            } else {
                let $next = el.nextSibling;
                if ($next != null) {

                    if ($next.innerText == '' || $next.innerText == ' ') {
                        wordArray[wordIndex].push(idx);
                        wordIndex++;
                        wordStart = true;
                    }
                } else {
                    wordArray[wordIndex].push(idx);
                }
            }
        });

        for (let i = 0; i < wordArray.length; i++) {
            let start = wordArray[i][0];
            let end = wordArray[i][1] + 1;

            let wrap = document.createElement('span');
            wrap.classList.add(WORD_CLASS);

            /* no end value pushed to array if only one word last line*/
            if (!end) {
                wrap.append($wordSpans[start]);
            } else {

                let slicedSpans = $wordSpans.slice(start, end);
                slicedSpans.forEach(el => {
                    wrap.append(el);
                });
            }

            this.el.append(wrap);
        }
    }

    initFromAnimation(mode) {

        let $wrap;

        if(this.splitMode == 'words') {
            $wrap = this.el.querySelectorAll(`.${WORD_CLASS}`);
        } else if(this.splitMode == 'lines') {
            $wrap = this.el.querySelectorAll(`.${LINE_CLASS}`);
        } else if(this.splitMode == 'chars') {
            $wrap = this.el;
        }

        if($wrap.length != undefined) {
            $wrap.forEach(($wrapItem) => {
                let animation;
                let $items;

                if(mode == 'chars' || mode == '') {
                    $items = $wrapItem.querySelectorAll(`.${CHAR_CLASS}`);
                } else if(mode =='words') {
                    $items = $wrap;
                } else {
                    // console.warn(`Animation can't be created because there is a conflict between your split type and the animation type`)
                    return;
                }

                gsap.set($items, {
                    ...this.DEFAULT_FROM_ANIMATION
                });

                animation = gsap.to($items,{
                    ...this.DEFAULT_TO_ANIMATION
                });

                this.animations.push(animation);
            });
        } else {
            let $items = $wrap.querySelectorAll(`.${CHAR_CLASS}`);
            let animation;

            gsap.set($items, {
                ...this.DEFAULT_FROM_ANIMATION
            });
            
            animation = gsap.to($items,{
                ...this.DEFAULT_TO_ANIMATION
            });
            this.animations.push(animation);
        }
    }

    animate() {
        
        this.el.classList.add('is-visible');

        this.animations.forEach((animation, index) => {
            gsap.delayedCall(this.DEFAULT_TO_ANIMATION.delay,() => {
                animation.progress(0);
                animation.play();

                if(index == 0) {
                    this.el.classList.add(ANIMATED_CLASS);
                }
            });
        });

        this.animated = true;

    }

}
