import { module } from 'modujs';
import Lenis from '@studio-freight/lenis';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { lazyLoadImage } from '../utils/image';

gsap.registerPlugin(ScrollTrigger);

// data-attributes
// data-scroll="item" : to add element as "detectable"
// data-scroll="to" with `href` or `data-href` to add a scroll to link

// data-scroll-id : need to be set for callbacks data-scroll-call & data-scroll-progress-call
// data-scroll-progress-call="progress,Timeline,{moduleId}" : call a function in another module with the data-scroll-id
// Call example : (data-scroll-call, data-scroll-progress-call)
// 2 choices
// data-scroll-id="myCustomId" data-scroll-call="trigger,myModule" will automatically call myModule with the id of myCustomId
// OR
// data-scroll-call="trigger,myModule,myCustomId"

// data-scroll-progress-reverse will call progress method from 1 to 0

// data-scroll-start refered to `start` value of scrollTrigger
// data-scroll-end refered to `end` value of scrollTrigger
// data-scroll-marker 
// data-scroll-repeat
// data-scroll-target - trigger when the target element is triggered

const IN_VIEW_CLASS = 'is-inview';

export default class extends module {
    constructor(m) {
        super(m);

        this.$scrollItems = this.$('item');
        this.scrollToItems = this.$('to');
        this.$wrapper = typeof this.getData('wrapper') == 'string' ? document.querySelector(this.getData('wrapper')) : window;
        this.windowWidth = window.innerWidth;
        
        this.events = {
            'click': {
                to: 'scrollTo'
            }
        }
    }

    ready() {

        this.id = this.el.getAttribute('data-module-scroll');

        // if(!window.isMobile) {
            this.lenis = new Lenis({
                wrapper: this.$wrapper,
                content: this.el,
                direction: 'vertical',
                smooth: true,
                smoothTouch: false,
                duration: 1.2,
                mouseMultiplier: 0.45,
                touchMultiplier: 2
            });
        
            //get scroll value
            this.lenis.on('scroll', ({ scroll, limit, velocity, direction, progress }) => {
                if(this.id == 'main') {
                    window.scrollInstance = { 
                        scroll,
                        velocity,
                        direction, 
                        progress
                    }
                }
            });

            this.raf(0);

        // }

        this.imagesLoading();    
        this.$wrapper.scrollTo(0,0);

        // Init elements to detect
        gsap.delayedCall(0.2,() => {
            this.initElements();
        });
        
    }

    imagesLoading() {
        let $images = Array.from(document.getElementsByTagName('img'));
        let count = 0;

        $images.forEach($image => {

            $image.addEventListener('load',() => {
                count++;

                if(count == $images.length) {
                    this.initContainerSize();
                }
            });
        });
    }

    initElements() {

        this.triggers = new Array(this.$scrollItems.length);
        this.scrollElements = new Array(this.$scrollItems.length);

        this.$scrollItems.forEach(($element, index) => {

            let scrollElement = this.createElement($element, index);
            let hasMarkers = typeof $element.getAttribute('data-scroll-marker') == 'string';
            let $target = typeof $element.getAttribute('data-scroll-target') == 'string' ? document.querySelector($element.getAttribute('data-scroll-target')) : $element

            let options = {
                scroller: this.$wrapper,
                trigger: $target,
                start: typeof $element.getAttribute('data-scroll-start') == 'string' ? $element.getAttribute('data-scroll-start') : 'top bottom',
                end: typeof $element.getAttribute('data-scroll-end') == 'string' ? $element.getAttribute('data-scroll-end') : 'bottom top',
                markers: hasMarkers,
                
                onEnter: () => {
                    this.onElementEnter(scrollElement,'down')
                },
                onLeave: () => {
                    this.onElementLeave(scrollElement,'down')
                },
                onEnterBack: () => {
                    this.onElementEnter(scrollElement,'up')
                },
                onLeaveBack: () => {
                    this.onElementLeave(scrollElement,'up')
                }
            }
            if(scrollElement.progressCallParameters != undefined) {
                options = {...options, 
                    onUpdate: (self) => {
                        this.onElementProgress(self, scrollElement);
                    }
                }
            }

            let sTrigger = ScrollTrigger.create(options);

            this.scrollElements.push({
                ...scrollElement,
                sTrigger
            });
        })
    }

    createElement($element, index) {
        let id = $element.getAttribute('data-scroll-id');
        let isRepeatable = typeof $element.getAttribute('data-scroll-repeat') == 'string';
        
        // Manage classic call
        let callParameters;
        if(typeof $element.getAttribute('data-scroll-call') === 'string') {

            let callParametersArray = $element.getAttribute('data-scroll-call').split(',');
            callParameters = {
                function: callParametersArray[0],
                module: callParametersArray[1],
                moduleId: (callParametersArray.length < 3) ? id : callParametersArray[2]
            }
            if(callParameters.moduleId == undefined) {
                console.warn(`You didn't specified a data-scroll-id, or a moduleId in your data-scroll-call`,this.el)
            }
        } else {
            callParameters = undefined;
        }

        // Manage progress call
        let progressCallParameters;
        if(typeof $element.getAttribute('data-scroll-progress-call') === 'string') {
            let progressCallParametersArray = $element.getAttribute('data-scroll-progress-call').split(',');

            progressCallParameters = {
                function: progressCallParametersArray[0],
                module: progressCallParametersArray[1],
                moduleId: (progressCallParametersArray.length < 3) ? id : progressCallParametersArray[2],
                isReversed: typeof $element.getAttribute('data-scroll-progress-reverse') == 'string'
            }
            if(progressCallParameters.moduleId == undefined) {
                console.warn(`You didn't specified a data-scroll-id, or a moduleId in your data-scroll-progress-call`, this.el)
            }
        } else {
            progressCallParameters = undefined;
        }

        return {
            $el: $element,
            id,
            isRepeatable,
            callParameters,
            progressCallParameters
        }
    }

    onElementProgress(self, scrollElement) {
            
        let progress = self.progress.toFixed(3);
        this.call(scrollElement.progressCallParameters.function,
            {
                progress:parseFloat(progress),
                isReversed: scrollElement.progressCallParameters.isReversed
            },
            scrollElement.progressCallParameters.module,
            scrollElement.progressCallParameters.moduleId
        );
    }

    onElementEnter(scrollElement, direction) {
        if(!scrollElement.isRepeatable && scrollElement.$el.classList.contains(IN_VIEW_CLASS)) {
            return;
        }
        scrollElement.$el.classList.add(IN_VIEW_CLASS);

        // Call js functions in a specific module with data-attribute
        if(scrollElement.callParameters != undefined) {
            this.call(scrollElement.callParameters.function,{mode: 'enter', direction: direction, $el: scrollElement.$el},scrollElement.callParameters.module, scrollElement.callParameters.moduleId);
        }
    }

    onElementLeave(scrollElement, direction) {
        if(!scrollElement.isRepeatable && scrollElement.$el.classList.contains(IN_VIEW_CLASS)) {
            return;
        }
        scrollElement.$el.classList.remove(IN_VIEW_CLASS);
        
        // Call js functions in a specific module with data-attribute
        if(scrollElement.callParameters != undefined) {
            this.call(scrollElement.callParameters.function,{mode: 'leave', direction: direction, $el: scrollElement.$el},scrollElement.callParameters.module, scrollElement.callParameters.moduleId);
        }
    }

    raf(time) {
        this.lenis.raf(time);
        this.rafInstance = requestAnimationFrame((time) => this.raf(time));
    }

    scrollTo(e) {
        if(typeof e.preventDefault == 'function') {
            e.preventDefault();
        }
        
        let target = typeof e.currentTarget.getAttribute('href') === 'string' ? e.currentTarget.getAttribute('href') : e.currentTarget.getAttribute('data-href');
        let duration = typeof e.currentTarget.getAttribute('data-scroll-to-duration') == 'string' ? parseFloat(e.currentTarget.getAttribute('data-scroll-to-duration')) : 2;
        let offset = typeof e.currentTarget.getAttribute('data-scroll-to-offset') == 'string' ? parseInt(e.currentTarget.getAttribute('data-scroll-to-offset')) : - document.querySelector('.c-menu').getBoundingClientRect().height;
        let immediate = typeof e.currentTarget.getAttribute('data-scroll-to-immediate') == 'string';

        
        this.lenis.scrollTo(target,{
            offset,
            duration,
            immediate,
            easing: (x) => x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2
        });
    }

    initContainerSize() {
        if (this.direction === 'horizontal') {
            let elWidth = 0;

            for (let childIndex = 0; childIndex < this.el.children.length; childIndex++) {
                const child = this.el.children[childIndex];
                elWidth += child.getBoundingClientRect().width;
            }
            this.el.style.setProperty('--scrollContainerWidth', elWidth + 'px');
        }
    }

    refresh() {
        this.scrollElements.forEach(scrollElement => {
            scrollElement.sTrigger.refresh();
        });
    }
    
    resize() {
        if(this.windowWidth != window.innerWidth) {
            this.initContainerSize();

            this.windowWidth = window.innerWidth;
        }

    }

     /**
     * Lazy load the related image.
     *
     * @see ../utils/image.js
     *
     * It is recommended to wrap your `<img>` into an element with the
     * CSS class name `.c-lazy`. The CSS class name modifier `.-lazy-loaded`
     * will be applied on both the image and the parent wrapper.
     *
     * ```html
     * <div class="c-lazy o-ratio u-4:3">
     *     <img data-scroll data-scroll-call="lazyLoad, Scroll, main" data-src="http://picsum.photos/640/480?v=1" alt="" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" />
     * </div>
     * ```
     *
     */
     lazyLoad(args) {
        // console.log('lazy load');
        lazyLoadImage(args.$el, null, () => {
            //callback
            // console.log('image loaded', args.$el);
        })
    }

    destroy() {
        this.scrollElements.forEach(scrollElement => {
            scrollElement.sTrigger.kill();
        });
        cancelAnimationFrame(this.rafInstance);
    }
}
