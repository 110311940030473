import { module } from 'modujs';
import { gsap } from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);

        this.$items = this.$('item');
        this.index = 0;
        this.oldIndex = -1;
        this.duration = 2;
    }

    init() {
        
    }

    trigger({mode}) {

        if(mode == 'enter') {
            this.launch();
        } else {
            this.stop();
        }
    }

    launch() {
        
        this.next();

        this.delayedCall = gsap.delayedCall(this.duration, () => {
            this.launch();
        });
    }

    next() {
        this.call('goto',this.index,'Shapes');

        this.$items.forEach((item, index) => {
            if(index == this.index) {
                item.classList.add('is-active');
            }
            if(index == this.oldIndex) {
                item.classList.remove('is-active');
                item.classList.add('is-leaving');

                gsap.delayedCall(0.6, () => {
                    item.classList.remove('is-leaving');
                });
            }
        });

        this.oldIndex = this.index;

        if(this.index < this.$items.length - 1) {
            this.index++;
        } else {
            this.index = 0;
        }
    }

    stop() {
        if(this.delayedCall != undefined) {
            this.delayedCall.kill();
        }
    }


}
