import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: 'trigger'
        }
    }

    init() {

    }

    trigger() {
        if(html.classList.contains('has-nav-open')) {
            this.call('close','Nav');
        } else {
            this.call('open','Nav');
        }
    }
}
