import { gsap } from 'gsap';

const ONE_WAY = 'one-way'

export default class RailMove {
    constructor(el, speed, isFixed, paused, scrollInfluence) {
        this.el = el
        this.isFixed = isFixed;

        this.containerWidth = 0
        this.requestAnimation = null
        this.scrollPosition = -1
        this.translation = 0

        this.grabbed = false
        this.preventClick = false
        this.paused = paused

        this.originalVelocity = -speed
        this.velocity = this.originalVelocity

        this.lastDelta = null

        this.initializeElements()

        this.scrollInfluence = scrollInfluence;

    }

    launch() {
        this.initializeEvents();
    }

    initializeElements() {
        this.$railMove = this.el.querySelectorAll('.js-rail-group-container')
        this.$railMoveChildren = this.el.querySelectorAll('.js-rail-item')
        this.getBCR()
    }

    initializeEvents() {
        this.update()
    }

    setContainerWidth(width) {
        this.containerWidth = width
    }

    getBCR() {
        this.railMoveBCR = this.$railMove[0].getBoundingClientRect()
    }

    updateScroll(scrollInstance) {

        if (!this.isFixed && scrollInstance !== undefined) {
            let scrollY = Math.round(scrollInstance.scroll) ? Math.round(scrollInstance.scroll) : 0;

            let newScrollPosition = scrollY - this.scrollPosition;
            this.scrollPosition = scrollY;

            if(this.scrollInfluence == ONE_WAY) {
                newScrollPosition = Math.abs(newScrollPosition);
            }

            if(newScrollPosition != 0)
               this.velocity = this.originalVelocity * newScrollPosition * 0.5;

        }
    }

    pause() {
        this.paused = true;
        cancelAnimationFrame(this.requestAnimation);
    }

    resume() {
        this.paused = false
        this.requestAnimation = requestAnimationFrame(() => this.update())
    }

    update(){
        if(!this.grabbed) {
            if((this.translation > this.railMoveBCR.width / 2) || (this.translation < -this.railMoveBCR.width / 2)) {
                this.translation = 0
            } else {
                this.translation = this.translation + this.velocity
            }
        }

        let translation;
        if(this.translation > 0) {
            translation = -this.containerWidth + (this.translation % this.containerWidth)
        } else {
            translation = this.translation % this.containerWidth
        }

        gsap.set(this.$railMoveChildren, { x: Math.round(translation), force3D: true })

        if(this.scrollInfluence) {
            this.updateScroll(window.scrollInstance)
        }

        if(!this.paused)
            this.requestAnimation = requestAnimationFrame(() => this.update())
    }

    destroy() {
        cancelAnimationFrame(this.requestAnimation)
        gsap.set(this.$railMove, { x: 0 })
        this.translation = 0;
    }
}