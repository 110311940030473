import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
         // init Swiper:
        this.swiper = new Swiper(this.el, {
            speed: 600,
            effect: 'fade',
            loop: true,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            }
        });

        this.stop();

        this.firstHit = true;
    }

    start() {
        this.swiper.slideTo(1,0);
        this.swiper.autoplay.start();

        if(this.firstHit) {
            let sources = this.el.querySelectorAll('picture>*');
            sources.forEach((source) => {
                if(source.getAttribute('srcset') == 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==') {
                    source.setAttribute('srcset',source.getAttribute('data-srcset'));
                    source.setAttribute('src',source.getAttribute('data-srcset'));
                }
            });
        }

        this.firstHit = false;
    }

    stop() {
        this.swiper.autoplay.stop();
    }
}
