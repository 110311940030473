import { module } from 'modujs';
import { gsap } from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);
        this.$items = this.$('item');
        this.index = 0;
    }

    init() {
    
    }

    goto(gotoIndex) {
        this.$items.forEach((item, index) => {
            if(item.classList.contains('is-active')) {
                item.classList.add('is-leaving');
                item.classList.remove('is-active');

                gsap.delayedCall(1.2, () => {
                    item.classList.remove('is-leaving');
                });
            }
            if(index == this.index) {
                item.classList.add('is-active');
            }
        });

        if(this.index < this.$items.length - 1) {
            this.index++;
        } else {
            this.index = 0;
        }
    } 

}
