import { module } from 'modujs';
import { gsap } from 'gsap';
import { shuffle } from '../utils/array';

const CLASS_IS_ACTIVE = 'is-active';
const CLASS_IS_LEAVING = 'is-leaving';

export default class extends module {
    constructor(m) {
        super(m);

        this.$itemsBase = Array.from(this.$('item'));
        this.duration = 6;
        this.delay = 1;
        this.currentIndex = 0;
        this.oldIndex = -1;
        this.isVisible = false;
        
        this.el.innerHTML = '';
        
        this.$items = shuffle(this.$itemsBase);
        
        this.$items.forEach(item => {
            this.el.appendChild(item);
        });

        if(window.isMobile) return;
        
        let nextSources = this.$items[0].querySelectorAll('picture>*');

        nextSources.forEach((source) => {

            if(source.getAttribute('srcset') == 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==') {
                source.setAttribute('srcset',source.getAttribute('data-srcset'));
                source.setAttribute('src',source.getAttribute('data-srcset'));
            }
        });
        
    }

    init() {

    }

    trigger({mode}) {
        if(mode == 'enter') {
            this.isVisible = true;
            this.launchSlider(2);
            
        } else {
            this.isVisible = false;
            this.stopSlider();
        }
    }

    launchSlider(delay) {
        this.next(delay);
    }
    
    stopSlider() {
        this.delayedCall.kill();
    }

    next(delay = this.delay) {
        if(window.isMobile) return;

        // load index+1 sources
        if(this.currentIndex < this.$items.length -1) {
        let nextSources = this.$items[this.currentIndex+1].querySelectorAll('picture>*');
            nextSources.forEach((source) => {
                if(source.getAttribute('srcset') == 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==') {
                    source.setAttribute('srcset',source.getAttribute('data-srcset'));
                    source.setAttribute('src',source.getAttribute('data-srcset'));
                }
            });
        }

        this.$items.forEach((item, index) => {
            if(index == this.currentIndex) {
                gsap.delayedCall(delay,() => {
                    item.classList.add(CLASS_IS_ACTIVE);
                });

            } else if(index == this.oldIndex){
                item.classList.add(CLASS_IS_LEAVING);
                item.classList.remove(CLASS_IS_ACTIVE);

                gsap.delayedCall(this.delay,() => {
                    item.classList.remove(CLASS_IS_LEAVING);
                });
            }
        });

        this.oldIndex = this.currentIndex;
        
        if(this.currentIndex < this.$items.length - 1) {
            this.currentIndex++;
        } else {
            this.currentIndex = 0;
        }

        if(this.isVisible) {
            this.delayedCall = gsap.delayedCall(this.duration,() => this.launchSlider());
        }
    }
}
