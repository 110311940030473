import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: 'onClick'
        }
    }

    init() {
    }


    onClick(e) {
        e.preventDefault();
        this.call('close','Nav');

        this.call('scrollTo',e,'Scroll','main');
    }
}
