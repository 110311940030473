import { module } from 'modujs';
import { shuffle } from '../utils/array';
import RailMove from './Railmove'

// data-module-rail="rail-1" 
// data-scroll="item" 
// data-scroll-call="trigger,Rail,rail-1"
// data-rail-speed="1" - optional
// data-rail-scroll - optional, usefull for scroll influence
// data-rail-scroll="one-way" - has scroll influence but with an ABS value, no way updates

export default class extends module {
    constructor(m) {
        super(m);

        // console.log()
        this.speed = this.el.getAttribute('data-rail-speed') || 1;
        this.isFixed = (typeof this.el.getAttribute('data-fixed') === 'string');
        this.shuffle = (typeof this.el.getAttribute('data-shuffle') === 'string');
        this.paused = (typeof this.el.getAttribute('data-paused') === 'string');
        this.scrollInfluence = this.el.getAttribute('data-rail-scroll') != null ? (this.el.getAttribute('data-rail-scroll') == '' ? true : this.el.getAttribute('data-rail-scroll')) : false;
        this.isMobile = (typeof this.el.getAttribute('data-desktop') === 'string') ? false : true
        this.initialHTML = this.el.outerHTML;

        this.isFirstHit = true;
    }

    init() {
        if(window.isMobile && !this.isMobile) {
            return;
        }

        this.setClasses()
        this.createTrack()
        this.fillScreen()

        this.groupTracks()
        this.duplicateGroup()
        this.wrapGroups()

        this.railMove = new RailMove(this.el, window.isMobile ? this.speed/2 : this.speed, this.isFixed, this.paused, window.isMobile ? false : this.scrollInfluence)
        this.railMove.setContainerWidth(this.trackGroupBCR.width)

    }

    setClasses() {
        this.el.classList.add('c-rail_wrapper')
        this.children = Array.from(this.el.children)

        for(let child of this.children) {
            child.classList.add('c-rail_item', 'js-rail-item');
        }
    }

    createTrack() {
        this.track = document.createElement('div')
        this.track.classList.add('c-rail_track')
        this.el.appendChild(this.track)

        this.tracks = []
        this.tracks.push(this.track)
        
       
        if(this.shuffle != undefined) {
            let shuffled = shuffle(this.children)
            
            for(let el of shuffled) {
                this.track.appendChild(el)
            }
        } else {
            for(let child of this.children) {
                this.track.appendChild(child)
            }
    
        }
    }

    fillScreen() {
        let ratio = window.innerWidth / this.track.getBoundingClientRect().width

        if(ratio === Infinity) {
            throw new Error('Ratio is Infinity');
        }

        for (var i = 0; i < ratio; i++) {
            let track = this.track.cloneNode(true)
            this.tracks.push(track)
            this.el.appendChild(track)
        }
    }

    groupTracks() {
        this.trackGroup = document.createElement('div')
        this.trackGroup.classList.add('c-rail_track-group')

        this.el.appendChild(this.trackGroup)

        for(let track of this.tracks)
            this.trackGroup.appendChild(track)

        this.trackGroupBCR = this.trackGroup.getBoundingClientRect()
    }

    duplicateGroup() {
        this.trackGroupClone = this.trackGroup.cloneNode(true)
        this.el.appendChild(this.trackGroupClone)
    }

    wrapGroups() {
        this.groupsWrap = document.createElement('div')
        this.groupsWrap.classList.add('js-rail-group-container')
        this.groupsWrap.classList.add('c-rail_group-container')
        this.el.append(this.groupsWrap)

        for(let group of [this.trackGroup, this.trackGroupClone])
            this.groupsWrap.append(group)
    }

    trigger(e) {
        if(window.isMobile && !this.isMobile) {
            return;
        }

        if(e.mode === 'enter') {
            if(this.isFirstHit) {
                this.railMove.launch();
                this.isFirstHit = false;
            } else {
                this.railMove.pause();
                this.railMove.resume();
            }
        } else {
            this.railMove.pause();
        }
    }

    launch() {
        this.railMove.launch();
    }

    destroy() {
        super.destroy();
    }
}